import doFetch from '../../fetch';
const MAINPATH = '/api/game';
const ACCPATH = '/api/acc/front';

//api/game/v1/member_report/category_list
// api/game/v1/member_report/fund_orders
/** 37.3 用戶申请取消 (https://docs.google.com/document/d/13aD97Urz7-mfS7joEzctRN8d9K303aobSmGkEHDtX2Q/edit#heading=h.mgiu4e3x8jxn
 * {
  "lang":"zh_cn",
  "platform_code":"OOO007",
  "order_no":"kk0aab35fd72740"
  }
*/
/**
 *   取得後台設置分類
 *    取得資金變動紀錄
 * @param data
 */
const actions = {
  config: async <T>() =>
    await doFetch<T>(`${MAINPATH}/v1/member_report/category_list`, 'POST', {
      device: 'mobile'
    }),
  read: async <T>(data: object) =>
    await doFetch<T>(`${MAINPATH}/v1/member_report/fund_orders`, 'POST', data),
  deleted: async <T>(data: object) =>
    await doFetch<T>(`${ACCPATH}/v1/withdrawals/user_cancel`, 'POST', data)
};

export const tradeReport = actions;

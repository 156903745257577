
import {Options, Vue} from 'vue-class-component';
import {ElMessage, ElDialog, ElLoading, ElPopconfirm} from 'element-plus';
import CustomTable from '@/common/web/table/index.vue';
import {App, Format} from '@/services';
import {GetUser} from '@/model/tokenGetUser';
import {tradeReport} from '@/services/server/wap/trade-report';
import {IType, ITypeResult, IFund, IFundResult, IUserCancelResult} from '@/model/trade-report';
import eventBus from '@/web/eventBus';

const moment = {
  getDate: function({
    year = new Date().getFullYear(),
    month = new Date().getMonth(),
    date = new Date().getDate(),
    hour = new Date().getHours(),
    minute = new Date().getMinutes(),
    second = new Date().getSeconds(),
    millisecond = new Date().getMilliseconds()
  }) {
    let now = new Date();
    const setMap = new Map([
      ['year', now.setFullYear(year)],
      ['month', now.setMonth(month)],
      ['date', now.setDate(date)],
      ['hour', now.setHours(hour)],
      ['minute', now.setMinutes(minute)],
      ['second', now.setSeconds(second)],
      ['millisecond', now.setMilliseconds(millisecond)]
    ]);
    setMap.forEach((value, key) => {
      const time = setMap.get(key) || +new Date();
      now = new Date(time);
    });
    return now;
  }
};

@Options({
  components: {
    ElDialog,
    ElLoading,
    CustomTable,
    ElPopconfirm
  }
})
export default class TradeReportDetail extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥',
    game_company: '[]'
  };
  userInfo = App.getUserinfo();
  get statusList() {
    console.log('statusList');
    return [
      {
        value: '',
        text: 'ui_all'
      },
      {
        value: 2,
        text: 'withdrawals_status[2]'
      },
      {
        value: 0,
        text: 'sys_check_pass'
      },
      {
        value: 1,
        text: 'sys_check_fail'
      }
    ];
  }
  sortable = {
    id: '0',
    name: 'ui_all'
  };
  status: {
    value: string | number;
    text: string;
  } = {
    value: '',
    text: 'ui_all'
  };

  search = {
    orderType: 1,
    page: 0,
    pageLimit: 5,
    totalPage: 2,
    category: '3',
    newTask: true,
    startDate: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD HH:MM:SS'),
    endDate: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD HH:MM:SS')
  };
  loading = false;
  tableLoading = false;
  today = new Date();
  activeTab = 0;
  showList: IFund[] = [];
  list: IFund[] = [];
  sortableList: IType[] = [];

  isLoading = false;
  pagination = {
    page: 0,
    pageLimit: 20,
    totalPage: 0,
    totalNumber: 0
  };
  titleList = [
    {
      name: 'rp_time', //時間
      key: 'time'
    },
    {
      name: 'rp_order', //訂單號
      key: 'order'
    },
    {
      name: 'rp_type', //交易类型
      key: 'type'
    },
    {
      name: 'rp_status', //狀態
      key: 'status'
    },
    {
      name: 'rp_amount', //金額
      key: 'amiunt'
    },
    {
      name: 'rp_remark',
      key: 'remark'
    }
  ];
  table = {
    keys: ['fundTime', 'fundId', 'typeName', 'statusText', 'amount', 'note']
  };
  tabs = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
  tabsIndex = 0;
  heigth = '';
  timer: null | number = null;
  get dayList() {
    return [
      {
        text: this.$t('ui_nowadays'),
        id: 0
      },
      {
        text: this.$t('ui_yesterday'),
        id: 1
      },
      {
        text: this.$t('ui_last_seven_days'),
        id: 6
      },
      {
        text: this.$t('ui_last_month'),
        id: 29
      }
    ];
  }

  async mounted() {
    if (!this.userInfo) {
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
    this.getSortableList();
    if (this.$route.params['date']) {
      this.activeTab = +this.$route.params.date;
    } else if (sessionStorage.getItem('date')) {
      this.activeTab = +(sessionStorage.getItem('date') || 0);
    } else {
      this.activeTab = 0;
    }
    this.changeVal(this.activeTab);

    eventBus.bus$on('langChange', () => {
      this.getSortableList(true);
      if (this.$route.params['date']) {
        this.activeTab = +this.$route.params.date;
      } else if (sessionStorage.getItem('date')) {
        this.activeTab = +(sessionStorage.getItem('date') || 0);
      } else {
        this.activeTab = 0;
      }
      this.onSearch(this.pagination.page);
    });
  }
  beforeDestroy() {
    eventBus.bus$off('langChange');
  }

  beforeUnmount() {
    localStorage.removeItem('fundTypes');
    sessionStorage.removeItem('date');
  }

  upperCase = Format.upperCase;

  async getSortableList(isFetch = false) {
    const getFundTypes = /{/.test(localStorage.getItem('fundTypes') || '')
      ? JSON.parse(localStorage.getItem('fundTypes') || '')
      : '';

    const data = getFundTypes && !isFetch ? getFundTypes : await tradeReport.config<ITypeResult>();
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      if (!getFundTypes || isFetch) {
        data.list.unshift({
          id: '0',
          name: 'ui_all'
        });
      }

      this.sortableList = data.list;
      localStorage.setItem('fundTypes', JSON.stringify(data));
    }
  }

  handleChange(item: {limit: number; page: number}) {
    console.log('handleChange item: ', item);
    this.search.pageLimit = item.limit;
    this.search.page = item.page;
    this.onSearch(item.page);
  }

  async onSearch(val?: {type: string} | number | undefined) {
    console.log('onSearch val: ', val);
    this.isLoading = true;
    this.tableLoading = true;
    //dosearch
    if (this.search.page > this.search.totalPage) {
      this.tableLoading = false;
      return;
    }
    if (this.search.page === 1) {
      this.showList = [];
    }
    const params = {
      page: this.search.page,
      page_limit: this.search.pageLimit,
      start_at: this.search.startDate,
      end_at: this.search.endDate,
      category: this.sortable.id !== '0' ? [this.sortable.id] : '',
      status: this.status.value,
      device: 'pc'
    };
    // await this.getList(this.$snakeCase(params));
    const data = await tradeReport.read<IFundResult>(params);
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      this.list = data.list;
      this.pagination = data.pagination;
      this.search = {
        ...this.search,
        ...data.pagination
      };
      this.showList = this.list;
    }
    setTimeout(() => {
      this.isLoading = false;
      this.tableLoading = false;
    });
  }
  onSortConfirm() {
    this.search.page = 0;
    this.onSearch();
  }
  onStatusConfirm() {
    this.search.page = 0;
    this.onSearch();
  }
  changeVal(val: number) {
    const [startDate, endDate] = this.setDate(val);
    this.search.page = 0;
    this.search.startDate = startDate;
    this.search.endDate = endDate;
    this.timer = window.setTimeout(() => {
      clearTimeout(this.timer || 0);
      sessionStorage.setItem('date', val + '');
      this.onSearch();
    });
  }

  async doDel(orderNo: string) {
    this.tableLoading = true;
    const data = await tradeReport.deleted<IUserCancelResult>({
      order_no: orderNo
    });
    ElMessage({
      type: data instanceof Error ? 'error' : 'info',
      message: data.message
    });
    setTimeout(() => {
      this.tableLoading = false;
    });
    this.onSearch(this.pagination.page);
    // this.countDownToast(data.message);
    console.log({data}, 'cancels');
  }
  setDate(val: number, formatPattern = 'YYYY-MM-DD HH:MM:SS') {
    let tempDate = new Date();
    if (typeof val === 'number') {
      tempDate = Format.TimeHandle.subtract(new Date(), val, 'date');
    }
    const dateMap = new Map([
      [
        0,
        [
          moment.getDate({hour: 0, minute: 0, second: 0}),
          moment.getDate({hour: 23, minute: 59, second: 59})
        ]
      ],
      [
        1,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        6,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        29,
        [
          moment.getDate({
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            date: tempDate.getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        60,
        [
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth() - 2,
            date: new Date().getDate(),
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ],
      [
        365,
        [
          moment.getDate({
            year: new Date().getFullYear() - 1,
            month: new Date().getMonth(),
            date: 1,
            hour: 0,
            minute: 0,
            second: 0
          }),
          moment.getDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            date: new Date().getDate(),
            hour: 23,
            minute: 59,
            second: 59
          })
        ]
      ]
    ]);
    const dateArr = dateMap.get(val) || [];
    return [
      Format.TimeHandle.format(dateArr[0], formatPattern),
      Format.TimeHandle.format(dateArr[1], formatPattern)
    ];
  }
}
